<template>
  <b-card v-if="officeData">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="FileTextIcon" size="19" />
      <h4 class="mb-0 ml-50">
        {{ $t('officesModel.generalInformation.title') }}
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit(officeData))">
        <b-row align-v="center" align-h="between">
          <!-- Field: Name-->
          <b-col cols="10">
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                :label="$t('officesModel.generalInformation.name')"
                label-for="name"
              >
                <b-form-input
                  v-model="officeData.name"
                  class="form-control"
                  :placeholder="$t('officesPlaceholders.name')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <b-row class="mt-1">
              <!-- Fields: estado -->
              <p class="mr-1" style="font-weight: bold">
                {{ $t('officesModel.generalInformation.active') }}
              </p>
              <b-form-checkbox
                v-model="officeData.isActive"
                name="check-button"
                switch
              >
                <span style="font-weight: 500">{{
                  officeData.isActive
                    ? $t('commons.switch.yes')
                    : $t('commons.switch.no')
                }}</span>
              </b-form-checkbox>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- Field: Phone -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Phone"
              rules="required"
            >
              <b-form-group
                :label="$t('officesModel.generalInformation.phone')"
                label-for="Phone"
              >
                <b-form-input
                  id="phone"
                  v-model="officeData.phone"
                  :placeholder="$t('officesPlaceholders.phone')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                :label="$t('officesModel.generalInformation.email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="officeData.email"
                  placeholder="johndoe@email.com"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Fax -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider #default="validationContext" name="Fax">
              <b-form-group
                :label="$t('officesModel.generalInformation.fax')"
                label-for="fax"
              >
                <b-form-input
                  id="fax"
                  v-model="officeData.fax"
                  placeholder="12346789"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Address -->
        <div class="d-flex mt-2">
          <feather-icon icon="MapPinIcon" size="19" />
          <h4 class="mb-0 ml-50">{{ $t('officesModel.address.title') }}</h4>
        </div>

        <b-row class="mt-1">
          <!-- Field: Address Line 1-->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Address Line 1"
              rules="required"
            >
              <b-form-group
                :label="$t('officesModel.address.addressLine1')"
                label-for="Address Line 1"
              >
                <b-form-input
                  id="addressline1"
                  v-model="officeData.addressLine1"
                  :placeholder="$t('officesPlaceholders.addressLine1')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Address Line 2 -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Address Line 2"
            >
              <b-form-group
                :label="$t('officesModel.address.addressLine2')"
                label-for="Address Line 2"
              >
                <b-form-input
                  id="addressline2"
                  v-model="officeData.addressLine2"
                  :placeholder="$t('officesModel.address.addressLine2')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Province -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="Province"
              rules="required"
            >
              <b-form-group
                :label="$t('officesModel.address.province')"
                label-for="Province"
              >
                <v-select
                  id="province"
                  :options="PROVINCES"
                  :clearable="false"
                  v-model="officeData.province"
                  placeholder="Province"
                  :state="getValidationState(validationContext)"
                  :disabled="true"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: City -->
          <b-col cols="12" md="6" lg="4">
            <validation-provider
              #default="validationContext"
              name="city"
              rules="required"
            >
              <b-form-group
                :label="$t('officesModel.address.city')"
                label-for="city"
              >
                <v-select
                  v-model="officeData.city"
                  :options="CITIES"
                  :clearable="false"
                  :state="getValidationState(validationContext)"
                  :placeholder="$t('officesPlaceholders.city')"
                />
                <b-form-invalid-feedback
                  class="form-control"
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2 pr-1" align-h="end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :to="{ name: 'offices-list' }"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
            ><b-spinner v-if="loading" small :label="$t('commons.loading')" />
            <span v-else>{{ $t('buttons.save') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  <SpinnerLoading v-else />
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
  BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'
import officeStoreModule from './officeStoreModule'
import OfficeModel from '@/models/office.model'
import useOfficeList from './useOfficeList'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerLoading from '@/components/SpinnerLoading.vue'
import { localize } from 'vee-validate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
    SpinnerLoading,
  },

  computed: {
    PROVINCES() {
      return this.$store.state.system.config.provinceOptions
    },

    CITIES() {
      return this.$store.state.system.config.cityOptions
    },
    loading() {
      return this.$store.state['app-office'].loading
    }
  },

  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      localize(newVal)
    },
  },

  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },

  setup() {
    const blankofficeData = new OfficeModel({
      province: 'MALAGA',
      isActive: false,
    })
    const officeData = ref(null)

    const office_APP_STORE_MODULE_NAME = 'app-office'

    const resetofficeData = () => {
      officeData.value = JSON.parse(JSON.stringify(blankofficeData))
    }

    const toast = useToast()

    // Register module
    if (!store.hasModule(office_APP_STORE_MODULE_NAME)) {
      store.registerModule(office_APP_STORE_MODULE_NAME, officeStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(office_APP_STORE_MODULE_NAME))
        store.unregisterModule(office_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-office/fetchOneOffice', router.currentRoute.params.id)
        .then((response) => {
          officeData.value = response
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.fetch.error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          if (error.response.status === 404) {
            officeData.value = undefined
          }
        })
    } else {
      officeData.value = blankofficeData
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetofficeData)

    const { onSubmit } = useOfficeList()

    return {
      officeData,
      refFormObserver,
      getValidationState,
      resetForm,

      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
